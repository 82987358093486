<template>
  <div>
    <!-- 号码管理 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <el-select v-model="queryParams.status" clearable
          placeholder="全部状态" @change="resetPageAndRefresh">
            <el-option label="待售" :value="0"></el-option>
            <el-option label="已售" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.operator" placeholder="请选择运营商" clearable filterable @change="refresh">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="queryParams.cardNumber" @blur="refresh" clearable placeholder="搜索号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="refresh">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" class="el-icon-download"><el-link :href="BaseUrl + 'net//admin/cardInfo/noLogin/downModule'" class="button_download">下载模板</el-link></el-button>
        </el-form-item>
        <el-form-item>
          <base-import importUrl="net/admin/cardInfo/import" @finish="refresh,numberRequire()" v-if="OA.includes('goods:number:lead')"></base-import>
        </el-form-item>
        <el-form-item>
          <div class="lable-num">已售 {{phoneNumber.yes}}</div>
        </el-form-item>
        <el-form-item>
          <div class="lable-num">未售 {{phoneNumber.no}}</div>
        </el-form-item>
      </el-form>

      <div class="content-wrap">
        <!-- <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" >添加套餐</el-button>
        </div> -->
    
        <!--列表-->
        <paged-table
          :data="numberList"
          :total="numberTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh(),numberRequire()"
        >
          <el-table-column prop="cardNumber" label="号码" :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="operator" label="运营商" :show-overflow-tooltip="true"></el-table-column>
          
          <el-table-column prop="predictValue" label="预存话费" :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column prop="operator" label="运营商" :show-overflow-tooltip="true"></el-table-column> -->
          <!-- <el-table-column prop="extend.schoolName" label="学校" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="addTime" label="创建时间" :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="extend.addUserName" label="创建者" :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">待售 </span>
              <span v-if="scope.row.status == 1">已售 </span>

              <el-switch v-model="scope.row.status" 
              :active-value="0"  active-color="#13ce66"
              :inactive-value="1"  inactive-color="#999"
              @change="statusChanged(scope.row),numberRequire()">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="right" label="操作" width="120" fixed="right"> 
            <template slot-scope="scope">   
              <!-- <el-button size="mini" icon="el-icon-search" title="查看"></el-button> 
              <el-button size="mini" type="primary" icon="el-icon-edit" title="编辑"></el-button> -->
              <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id}),numberRequire()" icon="el-icon-delete" title="删除"></el-button>
              <!-- <el-button size="mini" type="danger" icon="el-icon-delete" title="删除"></el-button>  -->
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'noticeManagement',
  components: {
    AreaTree,
    PagedTable,
    BaseImport
  },
  mixins: [pageMixin,provinces,download],
  data() {
    return {
      url: '',
      BaseUrl,
      queryParams: {
        cardNumber:'',
        pageNum:1,
        pageSize:10
      },
      schoolType: [],
      form:{
        id:'',
        schoolName:'',
        department:'',
        province:'',
        city:'',
        area:'',
        level: '',
        remark: ''
      },
      phoneNumber: {},
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      pageApi:'getNumber',
      deleteApi:'adminCardInfoDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("number", ["numberList", "numberTotal"]),
  },
  methods: {
     ...mapActions("number",["getNumber", "getNumberImport", "adminCardInfoEdit", "adminCardInfoDelete",'numberGetNumbers']),
  
    async statusChanged(row) {
      let obj = {
        id: row.id,
        status: row.status
      }
      const res = await this.adminCardInfoEdit(obj)
      this.refresh()
      this.$message.success("更新列表状态成功")
    },
    handleQuery(data){
      // log(data)
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
      }
      this.refresh()
    },
    async numberRequire(){
      let res = await this.numberGetNumbers()
      this.phoneNumber = res
    }
  },
  async mounted(){
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    this.numberRequire()
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
.lable-num { width: 100px; text-align: center; background: #28B892; color: #fff; border-radius: 5px; }
</style>
